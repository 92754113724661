import { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import UpdateBankAmount from "@shared/components/UpdateBankAmountCobro";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import CardContent from "@material-ui/core/CardContent";
import { getXcobrarLoans } from "@/Selectors";
import { printAmount, printAbsAmount, getUniqueID } from "@shared/functions";
import { printCuadreHandler } from "@shared/functions/print";
import PrintIcon from "@material-ui/icons/Print";
// import Alert from "@material-ui/lab/Alert";
import BottomNavigation from "../components/BottomNavigation";
import LinearProgressWithLabel from "@shared/components/LinearProgressWithLabel";
import { BsEyeSlashFill } from "react-icons/bs";
import { AiFillBank } from "react-icons/ai";
import LoadingInspect from "@shared/components/LoadingInspect";
import { useSelector, useDispatch } from "react-redux";
import { selectLoans } from "../store/slices/loans";
import { selectNewLoans } from "../store/slices/newLoans";
// import { selectCuadre } from "../store/slices/cuadre";
import { selectCommon, updateBank } from "../store/slices/common";
// import { selectDevice, selectClient, setClient, setDevice } from "../store/slices/miscData";
// import { UseCuadre } from "@shared/server/fetcher";
import { selectCollected, selectTotalCollected } from "../store/slices/payments";
import { selectGastos, selectTotalGastos } from "../store/slices/gastos";
import { selectTotalNewLoans } from "../store/slices/newLoans";
import { createQueue } from "../store/slices/syncronization";
import {
  useGetOwnerConfigQuery,
  useGetPermissionsQuery,
  useGetRouteQuery,
  useGetUserConfigQuery,
  useGetUserQuery,
} from "../store/slices/api";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    flexShrink: 0,
    flexBasis: "60%",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const CloseCuadre = () => {
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useDispatch();

  const common = useSelector(selectCommon);
  const loans = useSelector(selectLoans);
  const totalGastos = useSelector(selectTotalGastos);
  const totalCollected = useSelector(selectTotalCollected);
  const totalNewLoans = useSelector(selectTotalNewLoans);
  const gastos = useSelector(selectGastos);
  const newLoans = useSelector(selectNewLoans);
  const collected = useSelector(selectCollected);

  const { data: route } = useGetRouteQuery({
    creditor_id: common.creditor_id,
    user_id: common.user_id,
    collect_date: common.collect_date,
  });
  const { data: user } = useGetUserQuery(common.user_id);
  const { data: ownerConfig } = useGetOwnerConfigQuery(common.owner_id);
  const { data: permissions } = useGetPermissionsQuery({ creditor_id: common.creditor_id, user_id: common.user_id });
  const { data: userConfig } = useGetUserConfigQuery(common.user_id);
  // const { data: cuadre } = useGetCuadreQuery({
  //   creditor_id: common.creditor_id,
  //   collect_week_date: common.collect_week_date,
  // });

  const [xCobrar, setXcobrar] = useState({
    count: 0,
    amount: 0,
    list: [],
    failedAmount: 0,
    failedList: [],
    xCobrarTotal: 0,
    xCobrarCount: 0,
  });
  const [overWrittenPermissions, setOverwritePermissions] = useState(permissions ?? {});

  useEffect(() => {
    setOverwritePermissions({ ...permissions, ...common });
    setXcobrar(getXcobrarLoans(loans));
  }, [loans, common]);

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (!user || !permissions || !route || !ownerConfig || !userConfig)
    return (
      <LoadingInspect
        data={[
          {
            loaded: user && Object.keys(user).length > 0,
            label: "User",
          },
          {
            loaded: permissions && Object.keys(permissions).length > 0,
            label: "Permissions",
          },
          {
            loaded: ownerConfig && Object.keys(ownerConfig).length > 0,
            label: "OwnerConfig",
          },
          {
            loaded: route && Object.keys(route).length > 0,
            label: "Route",
          },
          {
            loaded: userConfig && Object.keys(userConfig).length > 0,
            label: "User Config",
          },
        ]}
      />
    );

  const handleCuadrePrint = (efectivo, deposits) => {
    const object = {
      route_name: route.route_name,
      location: route.location,
      collected_by: user.fname,
      cuadre_amount: xCobrar.xCobrarTotal,
      cuadre_count: xCobrar.xCobrarCount,
      today_date: common.today_date,
      xCobrar: xCobrar.failedAmount,
      newLoans: totalNewLoans.entregas,
      bank_amount: common.bank_amount,
      actanotarial: totalNewLoans.actanotarial,
      gastos: totalGastos,
      moras: totalCollected.mora,
      collected: totalCollected.collectedCash + totalNewLoans.adelanto,
      efectivo,
      deposits,
    };

    printCuadreHandler(object, { ...ownerConfig, ...userConfig });
  };

  const moraXDepositts = collected?.reduce((acc, obj) => {
    return obj.paymentType === "deposit" ? acc + +obj.paymentMora : acc;
  }, 0);

  const handleUnlockCuadre = () => {
    localStorage.setItem("awaitingAction", JSON.stringify({ type: "unLockCuadre" }));
    history.replace("/access/codes");
  };

  const updateBankAmount = (data) => {
    const syncProps = {
      syncronization_id: `R${common.creditor_id}_${common.collect_date}_${getUniqueID()}`,
      queueTime: Math.floor(Date.now()),
      uniqueType: "bankUpdate",
      endPoint: "/bgsync/cobro/bank/update",
      syncType: "updateBank",
      syncAmount: data.bankAmount,
      syncTitle: "Caja Banco Actualizado",
      syncName: null,
    };

    // //dispatch(removeNonUniqueType(syncProps));
    dispatch(createQueue({ postData: data, syncProps }));
    dispatch(updateBank(data));
    alert.info("Banco Actualizado!");
  };

  const cashMoney =
    totalNewLoans.adelanto +
    common.bank_amount +
    totalNewLoans.actanotarial +
    totalCollected.collectedCash +
    totalCollected.mora -
    totalGastos -
    totalNewLoans.entregas -
    moraXDepositts;

  const progress = xCobrar.xCobrarTotal > 0 ? (xCobrar.amount / xCobrar.xCobrarTotal) * 100 : 0;

  const RenderConditionalIcon = () => {
    if (
      overWrittenPermissions.perm_bank &&
      overWrittenPermissions.perm_cuadreAmount &&
      overWrittenPermissions.perm_efectivo
    )
      return (
        <>
          <Grid container>
            <Grid item xs={10}>
              <Typography variant="body1" component="p">
                (+) Caja / Banco:
                {printAmount(common.bank_amount)}
                <span className="p-1 text-primary">
                  {(overWrittenPermissions.perm_bank || [2149, "2149", 2163].includes(+common.owner_id)) && (
                    <UpdateBankAmount updateBankAmount={updateBankAmount} common={common} />
                  )}
                </span>
              </Typography>
            </Grid>

            <Grid item xs={2}>
              {overWrittenPermissions.perm_efectivo && (
                <PrintIcon
                  onClick={() => handleCuadrePrint(cashMoney, totalCollected.collectedDeposits)}
                  className="text-info"
                />
              )}
            </Grid>
          </Grid>
        </>
      );

    return (
      <>
        <Grid container>
          <Grid item xs={9}>
            <Typography variant="body1" component="p">
              (+) Caja / Banco:
              {printAmount(common.bank_amount)}
              <span className="p-1 text-primary">
                {(overWrittenPermissions.perm_bank || [2149, 2163].includes(+common.owner_id)) && (
                  <UpdateBankAmount updateBankAmount={updateBankAmount} common={common} />
                )}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {overWrittenPermissions.perm_efectivo && (
              <PrintIcon
                onClick={() => handleCuadrePrint(cashMoney, totalCollected.collectedDeposits)}
                className="text-info"
              />
            )}
          </Grid>
          <Grid item xs={1}>
            <BsEyeSlashFill onClick={handleUnlockCuadre} size="1.4em" className="text-primary" />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <div className="mt-3 mb-5">
      <div className="mt-3 mb-3">
        <Box component="div" p={1}>
          {/* {overWrittenPermissions.perm_cuadreAmount && (
            <Typography variant="h6" component="h4" className="mb-3">
              Estado de la ruta ({xCobrar.xCobrarCount}): {printAmount(xCobrar.xCobrarTotal)}
            </Typography>
          )} */}

          <Box component="div" paddingBottom={1}>
            <LinearProgressWithLabel value={progress} />
          </Box>

          <RenderConditionalIcon />
        </Box>
      </div>

      <Accordion expanded={expanded === "panel0"} onChange={handleChange("panel0")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel0bh-content" id="panel0bh-header">
          <Typography className={classes.heading}>Prestamos X Cobrar</Typography>
          {overWrittenPermissions.perm_efectivo && (
            <Typography className={classes.secondaryHeading}>(-) {printAmount(xCobrar.failedAmount)}</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 200, overflow: "auto", width: "100%", paddingLeft: 12 }}>
            <ol style={{ paddingLeft: 12 }}>
              {xCobrar.failedList.map((loan) => (
                <li key={loan.money_id}>
                  <Typography variant="body2" component="p">
                    {printAmount(loan.amount)} {loan.name}
                  </Typography>
                </li>
              ))}
            </ol>
            {xCobrar.failedList.length === 0 && (
              <Typography variant="body2" component="p">
                No prestamos sin Cobrar!
              </Typography>
            )}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
          <Typography className={classes.heading}>Entrega de Prestamos</Typography>
          {overWrittenPermissions.perm_efectivo && (
            <Typography className={classes.secondaryHeading}>(-) {printAmount(totalNewLoans.entregas)}</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ol style={{ paddingLeft: 12 }}>
            {newLoans.map((loan) => (
              <li key={loan.money_id}>
                <Typography variant="body2" component="p">
                  {printAmount(loan.amount)} {loan.name}
                </Typography>
              </li>
            ))}
          </ol>
          {totalNewLoans.entregas === 0 && (
            <Typography variant="body2" component="p">
              No prestamos registrado!
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header">
          <Typography className={classes.heading}>Acta Notarial</Typography>
          {overWrittenPermissions.perm_efectivo && (
            <Typography className={classes.secondaryHeading}>(+) {printAmount(totalNewLoans.actanotarial)}</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ol style={{ paddingLeft: 12 }}>
            {newLoans.map((loan) => (
              <li key={loan.money_id}>
                <Typography variant="body2" component="p">
                  {printAmount(loan.actanotarial)} {loan.name}
                </Typography>
              </li>
            ))}
          </ol>

          {totalNewLoans.actanotarial === 0 && (
            <Typography variant="body2" component="p">
              No acta Notarial registrada!
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel2v"} onChange={handleChange("panel2v")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2vbh-content" id="panel2vbh-header">
          <Typography className={classes.heading}>Pagos de Mora</Typography>
          {overWrittenPermissions.perm_efectivo && (
            <Typography className={classes.secondaryHeading}>(+) {printAmount(totalCollected.mora)}</Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ol style={{ paddingLeft: 12 }}>
            {collected
              .filter((x) => x.paymentMora > 0)
              .map((mora) => (
                <li key={mora.money_id}>
                  <Typography variant="body2" component="p">
                    {printAmount(mora.paymentMora)} {mora.name}
                  </Typography>
                </li>
              ))}
          </ol>

          {totalCollected.mora === 0 && (
            <Typography variant="body2" component="p">
              No pagos de Mora aplicado!
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
          <Typography className={classes.heading}>Pagos Aplicado</Typography>
          {overWrittenPermissions.perm_efectivo && (
            <Typography className={classes.secondaryHeading}>
              (+) {printAmount(totalCollected.collectedCash + totalNewLoans.adelanto)}
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 200, overflow: "auto", width: "100%", paddingLeft: 12 }}>
            <ol style={{ paddingLeft: 12 }}>
              {totalNewLoans.adelanto > 0 && (
                <li key="adelantoUniqueKey">
                  <i>{printAmount(totalNewLoans.adelanto)} Adelanto(s) de Entregas</i>
                </li>
              )}

              {collected
                .filter((x) => x.paymentType === "cash")
                .map((loan) => (
                  <li key={loan.payment_id}>
                    <Typography variant="body2" component="p">
                      {printAmount(loan.paymentAmount)} {loan.name}
                    </Typography>
                  </li>
                ))}
            </ol>
            {totalCollected.collectedCash === 0 && totalNewLoans.adelanto === 0 && (
              <Typography variant="body2" component="p">
                No pagos aplicado!
              </Typography>
            )}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel322"} onChange={handleChange("panel322")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel322bh-content" id="panel322bh-header">
          <Typography className={classes.heading}>Pagos Transferencia</Typography>
          {overWrittenPermissions.perm_efectivo && (
            <Typography className={classes.secondaryHeading}>
              (+) {printAmount(totalCollected.collectedDeposits)}
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: 200, overflow: "auto", width: "100%", paddingLeft: 12 }}>
            <ol style={{ paddingLeft: 12 }}>
              {collected
                .filter((x) => x.paymentType === "deposit")
                .map((loan) => (
                  <li key={loan.payment_id}>
                    <Typography variant="body2" component="p">
                      {printAmount(loan.paymentAmount)} {loan.name}
                    </Typography>
                  </li>
                ))}
            </ol>
            {totalCollected.collectedDeposits === 0 && (
              <Typography variant="body2" component="p">
                No pagos aplicado!
              </Typography>
            )}
          </div>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3bh-content" id="panel3bh-header">
          <Typography className={classes.heading}>
            <span className="text-danger">Gastos Aplicado</span>
          </Typography>
          {overWrittenPermissions.perm_efectivo && (
            <Typography className={classes.secondaryHeading}>
              <span className="text-danger">(-) {printAmount(totalGastos)}</span>
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ol style={{ paddingLeft: 12 }}>
            {gastos.map((gasto) => (
              <li key={gasto.gUniqueId}>
                <Typography variant="body2" component="p">
                  {printAmount(gasto.amount)} {gasto.description}
                </Typography>
              </li>
            ))}
          </ol>
          {totalGastos === 0 && (
            <Typography variant="body2" component="p">
              No gastos registrado!
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>

      {overWrittenPermissions.perm_efectivo && (
        <Card variant="outlined">
          <CardContent>
            {cashMoney >= 0 ? (
              <Typography variant="body1" gutterBottom>
                <b> ( = ) Dinero en Efectivo: {printAmount(cashMoney)}</b>
              </Typography>
            ) : (
              <Typography variant="body1" className="text-danger" gutterBottom>
                <b>( = ) Dinero en Efectivo: {printAbsAmount(cashMoney)} (Negativo)</b>
              </Typography>
            )}
          </CardContent>
        </Card>
      )}

      {overWrittenPermissions.perm_efectivo && totalCollected.collectedDeposits + moraXDepositts > 0 && (
        <Card variant="outlined" className="mt-1">
          <CardContent>
            <b>
              <span>
                <AiFillBank size="1.3em" /> Dinero de Transferencia:{" "}
                {printAbsAmount(totalCollected.collectedDeposits + moraXDepositts)}
              </span>
            </b>
          </CardContent>
        </Card>
      )}

      <br />
      <br />
      <BottomNavigation />
    </div>
  );
};

export default CloseCuadre;
